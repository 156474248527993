
import { defineComponent, inject, reactive } from '@vue/runtime-core';
import AppSplash from '../components/AppSplash.vue';
import AppDash from '../components/AppDash.vue';
import AppTopBar from '../components/AppTopBar.vue';
import AppSidebar from '../components/AppSidebar.vue';
import Layout from '../models/layout';
import SurveyLaunchForm from '../components/form/SurveyLaunchForm.vue';
import EpisodeCreationForm from '../components/form/EpisodeCreationForm.vue';
import EpisodeDischargeForm from '../components/form/discharge/EpisodeDischargeForm.vue';
import IntakeNonParticipationForm from '../components/form/discharge/IntakeNonParticipationForm.vue';
import ClaimEpisodeForm from '../components/form/ClaimEpisodeForm.vue';
import Episode from '@/models/foto/episode';
import ItemsForClose from '@/models/foto/itemsForClose';

export default defineComponent({
    name: 'App',
    components: {
        SurveyLaunchForm,
        EpisodeCreationForm,
        EpisodeDischargeForm,
        IntakeNonParticipationForm,
        ClaimEpisodeForm,
        AppSplash,
        AppTopBar,
        AppDash,
        AppSidebar,
    },
    setup() {
        const store: any = inject('store');
        const layout = reactive(new Layout(store.Context.TokenResponse?.user_id));

        return {
            store,
            layout
        };
    },
    data: function(): any {
        return {
            selectedEpisode: Episode,
            incompleteIntakeReasonList: [],
            noStatusReasonList: [],
            mipsExceptionsExclusionsList: [],
            isMipsEnabled: false,
            selectedId: null,
            outboundPatient: null,
            showClaimEpisodeForm: false,
            showSurvey: false,
            showDischargeForm: false,
            showEpisodeForm: false,
            showIntakeNonParticipationForm: false,
            showStatusIncompleteNonParticipationForm: false
        };
    },
    async created() {
        this.$Progress.start();
        (Window as any).$toast = this.$toast;
        (Window as any).$progress = this.$Progress;

        const started = performance.now();
        await this.store.InitAsync();
        const stopped = performance.now();

        this.outboundPatient = this.store.GetStateValue('FotoPatient');
        this.removeLoaderAfterElapsed(stopped - started);
    },
    computed: {
        speedDialItems: function(): Array<object> {
            const items = [
                {
                    label: this.layout.mode === 'static' ? 'Hide Sidebar' : 'Show Sidebar',
                    icon: this.layout.mode === 'static' ? 'pi pi-align-justify' : 'pi pi-align-right',
                    command: () => {
                        this.store.AddLog(`Toggling layout mode to ${this.layout.colorMode === 'static' ? 'overlay' : 'static'}`);
                        this.layout.toggleMode()
                    }
                },
                {
                    label: this.layout.colorMode === 'light' ? 'Dark Mode' : 'Light Mode',
                    icon: this.layout.colorMode === 'light' ? 'pi pi-moon' : 'pi pi-sun',
                    command: () => {
                        this.layout.loading = true;
                        this.store.AddLog(`Toggling color mode to ${this.layout.colorMode === 'light' ? 'dark' : 'light'}`);
                        setTimeout(() => this.layout.toggleColorMode(), 500)
                        setTimeout(() => { this.layout.loading = false; }, 2500);
                    }
                },
                {
                    label: 'Refresh Data',
                    icon: 'pi pi-refresh',
                    command: async () => { 
                        this.layout.loading = true;
                        this.store.AddLog('Refreshing data');
                        
                        const started = performance.now();
                        await this.store.Refresh();
                        const stopped = performance.now();

                        this.removeLoaderAfterElapsed(stopped - started);
                    },
                },
            ];
                    
            if(this.store.Context.PractitionerLaunch){
                items.unshift({
                    label: 'Create Episode',
                    icon: 'pi pi-plus-circle',
                    command: () => {
                        this.showEpisodeForm = true
                        this.store.AddLog('Displaying episode creation form.');
                    }
                });
            }

            return items;
        },
    },
    methods: {
        removeLoaderAfterElapsed(elapsed: number){
            if(elapsed < 2500){
                setTimeout(() => {
                    this.$Progress.finish();
                    this.layout.loading = false;                    
                }, 2500 - elapsed)
            }
            else{
                this.$Progress.finish();
                this.layout.loading = false;
            }

            setTimeout(() => { this.layout.loading = false; }, 2500);
        },
        toggleDischargeView(){
            this.layout.toggleDischargeView();
        },
        toggleCardEpisodeDisplay(){
            this.layout.onCardToggle();
        },
        displayEpisodeCreationModal(){
            this.showEpisodeForm = true;
        },
        displayEpisodeClaimModal(episodeId: number) {
            this.store.AddLog(`Displaying claim modal for episode with id ${episodeId}`);
            this.selectedId = episodeId;
            this.showClaimEpisodeForm = true;
        },
        displayDischargeModal(episode: Episode, items_for_close: ItemsForClose) {
            this.store.AddLog(`Displaying discharge modal for episode with external id ${episode.ExternalEpisodeId}`);
            this.selectedId = episode.ExternalEpisodeId;
            this.selectedEpisode = episode;

            if(items_for_close.CloseType == 1){
                this.incompleteIntakeReasonList = items_for_close.IncompleteIntakeReasonList;
                this.showIntakeNonParticipationForm = true;
            }
            else{
                this.showDischargeForm = true;
                this.isMipsEnabled = items_for_close.MIPSEnabled;
                this.mipsExceptionsExclusionsList= items_for_close.MIPSMIPSExceptionsExclusionsList;

                if(items_for_close.CloseType == 2)
                    this.noStatusReasonList = items_for_close.NoStatusReasonList;
                else
                    this.noStatusReasonList = [];
            }
        },
        displaySurveyModal(slotProps: any) {
            this.store.AddLog(`Displaying survey modal for episode with id ${slotProps.EpisodeId}`);
            this.selectedId = slotProps.EpisodeId;
            this.showSurvey = true;
        },
        isSidebarVisible() {
            if (window.innerWidth > 1024) {
                if (this.layout.mode === 'static') 
                    return !this.layout.staticMenuInactive;
                else if (this.layout.mode === 'overlay') 
                    return this.layout.overlayMenuActive;
            } 
            
            return true;
        },
        containerClass: function(): Array<Object> {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layout.mode === 'overlay',
                    'layout-static': this.layout.mode === 'static',
                    'layout-static-sidebar-inactive': this.layout.staticMenuInactive && this.layout.mode === 'static',
                    'layout-overlay-sidebar-active': this.layout.overlayMenuActive && this.layout.mode === 'overlay',
                    'layout-mobile-sidebar-active': this.layout.mobileMenuActive,
                },
            ];
        },
    },
});
