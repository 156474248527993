import EpisodeInfo from "./foto/episodeInfo";

export default class Layout{
    userId: string;
    menuActive: boolean;
    overlayMenuActive: boolean;
    loading: boolean;
    mobileMenuActive: boolean;
    menuClick: boolean;
    staticMenuInactive: boolean;
    colorMode: string;
    mode: string;
    showDischargedEpisodes: boolean;
    activeEpisodeFilters: Array<EpisodeInfo> = [
        EpisodeInfo.InProgress,
        EpisodeInfo.IntakeOverdue,
        EpisodeInfo.StatusOverdue,
        EpisodeInfo.Close,
        EpisodeInfo.Inactive,
    ];

    constructor(userid: string){
        this.userId = userid;
        this.menuActive = true;
        this.overlayMenuActive = false;
        this.loading = true;
        this.mobileMenuActive = false;
        this.menuClick = false;
        this.staticMenuInactive = false;
        this.colorMode = localStorage.getItem(`nh-foto-sof-color-mode-${userid}`);
        this.mode = localStorage.getItem(`nh-foto-sof-sidebar-mode-${userid}`) === 'overlay' ? 'overlay' : 'static';
        this.showDischargedEpisodes = localStorage.getItem(`nh-foto-sof-show-discharged-episodes-${userid}`) === 'false' ? false : true;
        const activeEpiFilters = localStorage.getItem(`nh-foto-sof-active-episode-filters-${userid}`);

        if(activeEpiFilters)
            this.activeEpisodeFilters = activeEpiFilters.split(',').map((f: string) => parseInt(f));

        this.colorMode === 'dark' ? this.setDark() : this.setLight();
    }

    toggleMode(){
        this.mode = this.mode === 'overlay' ? 'static' : 'overlay';
        localStorage.setItem(`nh-foto-sof-sidebar-mode-${this.userId}`, this.mode);
    }

    toggleColorMode(){
        this.colorMode === 'dark' ? this.setLight() : this.setDark();
        localStorage.setItem(`nh-foto-sof-color-mode-${this.userId}`, this.colorMode);
    }

    toggleDischargeView(){
        this.showDischargedEpisodes = !this.showDischargedEpisodes;
        localStorage.setItem(`nh-foto-sof-show-discharged-episodes-${this.userId}`, this.showDischargedEpisodes.toString());
    }

    onCardToggle(){
        localStorage.setItem(`nh-foto-sof-active-episode-filters-${this.userId}`, this.activeEpisodeFilters.join(','));
    }

    private setDark(){
        const body = document.querySelector('body');
        const themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace('light', 'dark'));

        body.classList.remove('light');
        this.colorMode = 'dark';
        body.classList.add('dark');
    }

    private setLight(){
        const body = document.querySelector('body');
        const themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace('dark', 'light'));

        body.classList.remove('dark');
        this.colorMode = 'light';
        body.classList.add('light');
    }
}