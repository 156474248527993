import { createApp } from 'vue';
import App from './App.vue';

//FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faRocket, faPaperPlane, faEnvelope, faQrcode, faFileDownload, faEye, faGenderless, faMars, faVenus, faUser, faBookMedical, faCalendarDay, faAllergies, faSyringe, faPrescriptionBottleAlt, faExclamationTriangle, faFrown } from '@fortawesome/free-solid-svg-icons';
library.add(faRocket, faPaperPlane, faEnvelope, faQrcode, faEye, faFileDownload, faGenderless, faMars, faVenus, faUser, faBookMedical, faCalendarDay, faAllergies, faSyringe, faPrescriptionBottleAlt, faExclamationTriangle, faFrown);

//PrimeVue
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chart from 'primevue/chart';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import ScrollPanel from 'primevue/scrollpanel';
import Slider from 'primevue/slider';
import Skeleton from 'primevue/skeleton';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import TabMenu from 'primevue/tabmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline';
import Tooltip from 'primevue/tooltip';
import VueProgressBar from "@aacassandra/vue3-progressbar";

export function createVueApp(){
    const app = createApp(App);

    app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
	app.use(ToastService);
	app.use(VueProgressBar, {
		color: "#0388E5",
		failedColor: "red",
		thickness: "4px",
		location: 'top',
		autoRevert: true,
		inverse: false,
		autoFinish: false,
		transition: {
			speed: ".1s",
			opacity: "0s",
			termination: 800,
		}
	});
	
	app.component('font-awesome-icon', FontAwesomeIcon);
	app.component('Accordion', Accordion);
	app.component('AccordionTab', AccordionTab);
	app.component('Avatar', Avatar);
	app.component('Button', Button);
	app.component('Calendar', Calendar);
	app.component('Card', Card);
	app.component('Chart', Chart);
	app.component('Checkbox', Checkbox);
	app.component('Column', Column);
	app.component('ColumnGroup', ColumnGroup);
	app.component('ContextMenu', ContextMenu);
	app.component('DataTable', DataTable);
	app.component('Dialog', Dialog);
	app.component('Divider', Divider);
	app.component('Dropdown', Dropdown);
	app.component('Fieldset', Fieldset);
	app.component('Image', Image);
	app.component('InlineMessage', InlineMessage);
	app.component('Inplace', Inplace);
	app.component('InputNumber', InputNumber);
	app.component('InputSwitch', InputSwitch);
	app.component('InputText', InputText);
	app.component('Menu', Menu);
	app.component('MultiSelect', MultiSelect);
	app.component('OverlayPanel', OverlayPanel);
	app.component('Password', Password);
	app.component('ProgressBar', ProgressBar);
	app.component('ProgressSpinner', ProgressSpinner);
	app.component('Row', Row);
	app.component('ScrollPanel', ScrollPanel);
	app.component('Slider', Slider);
	app.component('Sidebar', Sidebar);
	app.component('Skeleton', Skeleton);
	app.component('SpeedDial', SpeedDial);
	app.component('SplitButton', SplitButton);
	app.component('TabMenu', TabMenu);
	app.component('TabView', TabView);
	app.component('TabPanel', TabPanel);
	app.component('Tag', Tag);
	app.component('Textarea', Textarea);
	app.component('Timeline', Timeline);
	app.component('Toast', Toast);
	app.directive('tooltip', Tooltip);
	app.directive('ripple', Ripple);
	app.directive('badgedirective', BadgeDirective);

    return app;
}