export default class PatientVisitHours {
    OTHours: number = 0;
    OTVisits: number = 0;
    PTHours: number = 0;
    PTVisits: number = 0;
    SLPHours: number = 0;
    SLPVisits: number = 0;
    RTHours: number = 0;
    RTVisits: number = 0;
    RNHours: number = 0;
    RNVisits: number = 0;
    OtherHours: number = 0;
    OtherVisits: number = 0;
    TotalHours: number = 0;
    TotalVisits: number = 1;
}