
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';
import ApplicationStore from '../../../stores/applicationStore';
import Discharge from '../../../models/foto/discharge';
import ToastService from '@/services/toastService';
import Episode from '@/models/foto/episode';
import NonParticipationNoStatus from '@/models/foto/nonParticipationNoStatus';

export default defineComponent({
    name: 'EpisodeDischargeForm',
    setup() {
        return {
            store: inject('store') as ApplicationStore,
            v$: useVuelidate(),
        };
    },
    props: {
        episode: Episode,
        isMipsEnabled: Boolean,
        mipsExceptionsExclusionsList: Array,
        noStatusReasonList: Array
    },
    data: function(): any {
        return {
            discharge: new Discharge() as Discharge,
            incomplete_status: new NonParticipationNoStatus(),
            submitted: false,
            loading: false,
            hasIntakeException: true,
            goals: [{ value: "100" }, { value: "75 - 99" }, { value: "50 - 74" }, { value: "25 - 49" }, { value: "1 - 24" }, { value: "0" }],
            whoDischarged: [
                { label: "Clinician", value: "clinician" }, 
                { label: "Patient", value: "patient" },
                { label: "Physician", value: "physician" },
                { label: "Insurance", value: "insurance" },
                { label: "Other", value: "other" }
            ]
        };
    },
    methods: {
        async submit() {
            try{
                this.submitted = true;
                
                if (!this.v$.discharge.$invalid && !this.v$.incomplete_status.$invalid) {
                    this.store.AddLog('Discharging episode', this.episode);
                    this.loading = true;
                    this.$Progress.start();

                    let response = null;

                    if(this.noStatusReasonList.length > 0 && this.hasIntakeException){
                        this.incomplete_status.EpisodeId = this.episode.EpisodeId;

                        response = await this.store.Episode.NonParticipationNoStatus(this.incomplete_status);

                        if(response.successful && response.data.ResultCode == 1)
                            ToastService.Success("Episode closed successfully.");
                        else if(response.data.ResultCode == 0)
                            ToastService.Warning(response.data.Errors.join(', '));
                        else
                            throw new Error(`Error closing episode: ${response.errorMessage}`);
                    }
                    else{
                        this.discharge.EpisodeId = this.episode.EpisodeId;
                        this.discharge.PatientVisitsHours.TotalVisits = 1;
                        
                        if(this.noStatusReasonList.length > 0)
                            this.discharge.NoStatusReasonId = this.incomplete_status.ReasonId;

                        response = await this.store.Episode.DischargeV2(this.discharge);

                        if(response.successful && response.data.ResultCode === 2)
                            ToastService.Success("Episode discharged successfully.");
                        else if(response.data.ResultCode === 1)
                            ToastService.Warning(response.data.Errors.join(', '));
                        else if(response.data.ResultCode === 0) 
                            ToastService.Error(response.data.Errors.join(', '));
                        else 
                            throw new Error(`Error discharging episode: ${response.errorMessage}`);
                    }

                    this.submitted = false;
                    this.loading = false;
                    this.$emit('close');
                    this.$Progress.finish();
                }
            }
            catch(er){
                this.submitted = false;
                this.loading = false;
                this.$Progress.finish();
                
                Promise.reject(er);
            }
        },
        onNoStatusReasonChange(){
            const intakeExceptionOptions = this.noStatusReasonList.filter(nsrl => nsrl.groupName === "Intake Exceptions")[0].items.map(i => i.value);
            
            if(intakeExceptionOptions.includes(this.incomplete_status.ReasonId.toString()))
                this.hasIntakeException = true;
            else
                this.hasIntakeException = false;
        }
    },
    validations() {
        return {
            discharge: {
                LastVisit: {
                    required: requiredIf(function() {
                        return this.noStatusReasonList.length == 0 || !this.hasIntakeException
                    })
                }
            },
            incomplete_status: {
                ReasonId: {
                    required: requiredIf(function() {
                        return this.noStatusReasonList.length > 0;
                    })
                }
            }
        };
    },
});
