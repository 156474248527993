import Patient from "@/models/foto/patient";
import ApiService from "@/services/apiService";
import PatientService from "@/services/foto/patientService";
import { reactive, readonly } from "vue";
import ArtifactStore from "./artifactStore";
import CodeStore from "./codeStore";
import EpisodeStore from "./episodeStore";
import FhirStore from "./fhirStore";

export default class ApplicationStore {
    private _emrLaunchContent: any;
    private _context: any = reactive({
        State: {},
        Log: []
    });

    public HasFinishedLaunch: boolean;
    public Context: any = readonly(this._context);
    public Episode: EpisodeStore;
    public Code: CodeStore;
    public Artifact: ArtifactStore;
    public Fhir: FhirStore;

    constructor(emrLaunchContext: any){
        this.HasFinishedLaunch = false;
        this._emrLaunchContent = emrLaunchContext;
        
        this.SetContextFromEmr(emrLaunchContext);
        
        this.Code = new CodeStore();
        this.Artifact = new ArtifactStore();
        this.Episode = new EpisodeStore();
        this.Fhir = new FhirStore(emrLaunchContext.tokenResponse.encounter, emrLaunchContext.tokenResponse.patient, emrLaunchContext.tokenResponse.user_id);
    }

    private SetContextFromEmr(emrLaunchContext: any): void {
        this._context.UserName = emrLaunchContext.userName;
        this._context.LocationName = emrLaunchContext.locationName;
        this._context.Iss = emrLaunchContext.iss;
        this._context.PractitionerLaunch = emrLaunchContext.practitionerLaunch;
        this._context.TokenResponse = emrLaunchContext.tokenResponse;
    }

    async InitAsync(){
        await this.Fhir.InitAsync();
        const patientService = new PatientService();
        this._context.State.FotoPatient = Patient.FromResource(this.Fhir.State.Patient, this._context.TokenResponse.managing_organization);

        await patientService.Create(this._context.State.FotoPatient);
        await this.Episode.SetPatientEpisodes(this._context.State.FotoPatient.ExternalID as string);
        this.SetStateValue("FhirPatient", this.Fhir.State.Patient);

        this.HasFinishedLaunch = true;
    }

    async Refresh(){
        this._context = {
            State: {},
            Log: this._context.Log
        };
        this.SetContextFromEmr(this._emrLaunchContent);

        await ApiService.RefreshCache();
        await this.InitAsync();
    }

    SetContextValue(key: string, value: any){
        this._context[key] = value;
        this.AddLog(`Set context value ${key}`);
    }

    GetContextValue(key: string){
        return this._context[key];
    }

    SetStateValue(key: string, value: any){
        this._context.State[key] = value;
        this.AddLog(`Set state value ${key}`);
    }

    GetStateValue(key: string){
        return this._context.State[key];
    }

    AddLog(log: string, data: any = null, serialize = true){
        this._context.Log.push(`${new Date().toLocaleString()} - ${log}${data ? serialize ? ` - ${JSON.stringify(data)}` : ` - ${data}` : ''}`);
    }
}
