import Episode from '@/models/foto/episode';
import ApiService from '@/services/apiService';
import Visit from '@/models/foto/visit';
import Discharge from '@/models/foto/discharge';
import SurveyDetails from '@/models/foto/surveyDetails';
import ItemsForClose from '@/models/foto/itemsForClose';
import NonParticipationIncompleteIntake from '@/models/foto/nonParticipationIncompleteIntake';
import NonParticipationNoStatus from '@/models/foto/nonParticipationNoStatus';

export default class EpisodeService {
    async Claim(episode: Episode, externalEpisodeId: string) : Promise<any>{
        const result = await ApiService.Fetch(new Request(`/api/Foto/episode/json/claim2`, {
            method: 'PUT',
            body: JSON.stringify({
                episodeId: episode.EpisodeId,
                externalEpisodeId: externalEpisodeId  
            })
        }));

        if(result.successful && result.data.Success)
            episode.ExternalEpisodeId = externalEpisodeId;

        return result;
    }

    async DeleteVisit(visitId: number) : Promise<any>{
        const resp = await ApiService.Fetch(new Request(`/api/Foto/visit2/${visitId}`, { method: 'DELETE' }));

        if(resp.successful)
            return resp;

        throw resp;
    }

    async DeleteClaim(episode: Episode, externalEpisodeId: string) : Promise<any>{
        const result = await ApiService.Fetch(new Request(`/api/Foto/episode/${episode.EpisodeId}/${externalEpisodeId}`, { method: 'DELETE' }));

        if(result.successful)
            episode.ExternalEpisodeId = null;

        return result;
    }

    async DeleteDischarge(episode: Episode): Promise<any>{
        const response = (await ApiService.Fetch(new Request(`/api/Foto/discharge/json/${episode.ExternalEpisodeId}`, { method: 'DELETE'})));

        if(response.successful && response.data)
            episode.DischargeTime = null;

        return response;
    }

    async GetAll(patientExternalId: string): Promise<Array<Episode>> {
        const response = await ApiService.FetchAndParseResponseData<any>(new Request(`/api/Foto/episode/json/GetEpisodeStatus/${patientExternalId}/Both`, { method: 'GET' }));
        response.forEach((e: any) => { e.ExternalPatientId = patientExternalId; });

        return response.map((e: any) => Object.assign(new Episode(), e));
    }

    async GetSurvey(episodeId: string): Promise<SurveyDetails>{
        const details = new SurveyDetails();

        details.Url = await ApiService.FetchAndParseResponseData<any>(new Request(`/api/Foto/episode/SurveyURL/${episodeId}`, {  method: 'GET' }));
        details.LobbyCode = await ApiService.FetchAndParseResponseData<any>(new Request(`/api/Foto/episode/SurveyLobbyCode/${episodeId}`, { method: 'GET' }));
        details.QrCode = await ApiService.FetchAndParseResponseData<any>(new Request(`/api/Foto/episode/SurveyQRcode/${episodeId}`, { method: 'GET' }));

        return details.Url === '' || details.QrCode === '' ? null : details;
    }

    async GetCloseItems(episodeId: number): Promise<Array<ItemsForClose>>{
        return await ApiService.FetchAndParseResponseData<Array<ItemsForClose>>(new Request(`/api/Foto/episode/GetNeededItemsForClose/${episodeId}`, { method: 'GET' }));
    }

    async AddVisit(visit: Visit): Promise<any>{
        return await ApiService.Fetch(new Request(`/api/Foto/visit2/json`, {
            method: 'POST',
            body: JSON.stringify(visit)
        }));
    }

    async Discharge(episode: Episode, discharge: Discharge): Promise<any>{
        const response = await ApiService.Fetch(new Request(`/api/Foto/discharge/json`, {
            method: 'POST',
            body: JSON.stringify(discharge)
        }));

        if(response.successful && response.data.ResultCode === 2)
            episode.DischargeTime = new Date();

        return response;
    }

    async DischargeV2(episode: Episode, discharge: Discharge): Promise<any>{
        const response = await ApiService.Fetch(new Request(`/api/Foto/discharge2/json`, {
            method: 'POST',
            body: JSON.stringify(discharge)
        }));

        if(response.successful && response.data.ResultCode === 2)
            episode.DischargeTime = new Date();

        return response;
    }

    async NonParticipationIncompleteIntake(npii: NonParticipationIncompleteIntake): Promise<any>{
        return await ApiService.Fetch(new Request(`/api/Foto/nonparticipation/json/IncompleteIntake`, {
            method: 'POST',
            body: JSON.stringify(npii)
        }));
    }
    
    async NonParticipationNoStatus(npnn: NonParticipationNoStatus): Promise<any>{
        return await ApiService.Fetch(new Request(`/api/Foto/nonparticipation/json/NoStatus`, {
            method: 'POST',
            body: JSON.stringify(npnn)
        }));
    }

    async Create(episode: Episode): Promise<any> {
        return await ApiService.Fetch(new Request(`/api/Foto/episode/json/Creation`, {
            method: 'POST',
            body: JSON.stringify(episode.Json)
        }));
    }

    async GetTotalVisits(episodeId: number): Promise<any> {
        return await ApiService.Fetch(new Request(`/api/Foto/visit2/json/GetTotalVisitsByEpisodeId/${episodeId}`, { method: 'GET' }));
    }
}