import * as R4 from 'fhir/r4';
import ApiService from './apiService';

export default class FhirService {
    public async Search<Type extends R4.Resource>(reference: string): Promise<Type> {
        return await ApiService.FetchAndParseResponseData<Type>(new Request(`/api/Emr/Fhir/Search/${reference}`, { method: 'GET' }), true);
    }

    public async ExpandValueSet<ValueSet>(query: string): Promise<ValueSet> {
        return await ApiService.FetchAndParseResponseData<ValueSet>(new Request(`/api/Emr/Fhir/ValueSet/$expand?${query}`, { method: 'GET' }), true);
    }

    public async Read<Type extends R4.Resource>(reference: string): Promise<Type> {
        return await ApiService.FetchAndParseResponseData<Type>(new Request(`/api/Emr/Fhir/Read/${reference}`, { method: 'GET' }), true);
    }

    public async Post(resource: R4.Resource): Promise<any> {
        return await ApiService.Fetch(new Request(`/api/Emr/Fhir`, { 
            method: 'POST' ,
            body: JSON.stringify(resource)
        }));
    }
}