import ApiResponse from '../models/api/apiResponse';

export default class ApiService {
    private static readonly _cacheKey: string = "foto-sof-cache";

    private static IsValid(response: Response, expiration_in_hours: number) {
        if (!response) 
            return false;
        
        const fetched = response.headers.get('sw-fetched-on');
        if (fetched && (parseFloat(fetched) + (1000 * 60 * 60 * expiration_in_hours)) > new Date().getTime())
            return true;
        
        return false;
    }

    static async Fetch(request: Request, should_cache: boolean = false, expiration_in_hours: number = 12): Promise<ApiResponse> {
        const cache = await caches.open(this._cacheKey);
        const match = await cache.match(request.url);
        
        if(should_cache && match && match.ok && this.IsValid(match, expiration_in_hours)){
            return await match.json() as ApiResponse;
        }
        else{
            const token = new URLSearchParams(document.cookie.replaceAll("; ","&")).get("token");
            request.headers.set("Content-Type", "application/json");
            
            if(token)
            request.headers.append("Authorization", `Bearer ${token}`);
            
            const response = await fetch(request);

            try{
                const copy = response.clone();
                const content = await response.json() as ApiResponse;
    
                if(response.ok && content.successful){
                    if(should_cache){
                        const blob = await copy.blob();
                        const headers = new Headers(copy.headers);
                        headers.delete("Authorization");
                        headers.append('sw-fetched-on', `${new Date().getTime()}`);
    
                        await cache.put(request.url, new Response(blob, {
                            status: copy.status,
                            statusText: copy.statusText,
                            headers: headers,
                        }))
                    }
                }
    
                return content;
            }
            catch(ex: any)
            {
                throw new Error(`Error with request ${request.url}. Message: ${ex.message}`)
            }
        }
    }

    static async FetchAndParseResponseData<T>(request: Request, should_cache: boolean = false, expiration_in_hours: number = 12): Promise<T> {
        const api_response = await this.Fetch(request, should_cache, expiration_in_hours);

        if(api_response.successful)
            return api_response.data as T;
        else
            throw api_response;
    }

    static async DeleteCacheItem(requestUrl: string){
        const cache = await caches.open(this._cacheKey);
        const match = await cache.match(requestUrl);

        if(match)
            return await cache.delete(requestUrl);
    }

    static async RefreshCache(): Promise<boolean> {
        return await caches.delete(this._cacheKey);
    }
}