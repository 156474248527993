
import { defineComponent, inject } from '@vue/runtime-core';
import { PropType } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import Artifact from '@/models/foto/artifact';
import { FunctionalAssessment } from '@/models/foto/functionalAssessment';
import { PatientReported } from '@/models/foto/patientReported';
import { StagingFunctionalityPrediction } from '@/models/foto/stagingFunctionalityPrediction';
import { AssessmentAttribute } from '@/models/foto/assessmentAttribute';

export default defineComponent({
    name: 'RiskCriteria',
    props: {
        artifact: Object as PropType<Artifact>,
    },
    setup() {
        return { 
            store: inject('store') 
        };
    },
    data() {
        return {
            surveys: [],
            activeIndex: 0,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    created() {
        const unique_surveys = [];
        const artifact = JSON.parse(JSON.stringify(this.artifact)); //remove reactivity

        let surveys = artifact.FunctionalAssessments.map((i: FunctionalAssessment) => {
            const isPrimary = i.AssessmentAttributes.filter((aa: AssessmentAttribute) => ["isprimarymeasure"].includes(aa.Attribute.toLowerCase()) && aa.Value.toLowerCase() === "true")[0];
            const isOyw = i.AssessmentAttributes.filter((aa: AssessmentAttribute) => ["isderivedfromcrossscore", "isderivedfromcrosswalk"].includes(aa.Attribute.toLowerCase()) && aa.Value.toLowerCase() === "true")[0];
            const fuctionalScoreSummaries = i.FunctionalScoreSummaries as any;

            i.AssessmentAttributes.forEach((aa: AssessmentAttribute) => {
                if(aa.Attribute.toLowerCase().includes("intake") || aa.Attribute.toLowerCase().includes("status") && aa.Value != "0")
                    fuctionalScoreSummaries.push({
                        Label: aa.Attribute,
                        FunctionalScore: aa.Value
                    });
            });

            return {
                name: isPrimary ? `${i.Descriptor} (Primary)` : i.Descriptor,
                attributes: i.AssessmentAttributes,
                oyw: isOyw ? true : false,
                values: i.PatientResponses,
                scoreSummaries: i.FunctionalScoreSummaries,
                isPatientReported: false,
                predictions: (question) => {
                    const prediction = i.StagingInfo?.FunctionalityPredictions.filter((fp: StagingFunctionalityPrediction) => fp.Question.toLowerCase() === question.toLowerCase())
                    return prediction && prediction.length > 0 ? prediction[0].Answer : '--';
                }
            }      
        });
        
        artifact.PatientReportedItems.forEach((element: any) => {
            if(!unique_surveys.includes(element.Survey)){
                surveys.push({
                    name: element.Survey === "NA" ? "Additional Information" : element.Survey,
                    values: artifact.PatientReportedItems.filter((i: PatientReported) => i.Survey.toLowerCase() === element.Survey.toLowerCase()),
                    isPatientReported: true,
                    scoreSummaries: []
                });

                unique_surveys.push(element.Survey);
            }
        });

        surveys = surveys.filter((s: any) => {
            const surveys_with_same_name = surveys.filter((su: any) => su.name === s.name);

            if((surveys_with_same_name.length > 1 && s.scoreSummaries.length === 0) || !s.isPatientReported && s.oyw && s.attributes?.length === 0)
                return false;

            return true;
        })

        this.surveys = [...new Set(surveys)];
    },
});
