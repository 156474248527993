
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';
import Episode from '../../models/foto/episode';
import Code from '../../models/code';
import ApplicationStore from '../../stores/applicationStore';
import ToastService from '@/services/toastService';

export default defineComponent({
    name: 'EpisodeCreationForm',
    setup() {
        const store = inject('store') as ApplicationStore;
        const patient = store.GetStateValue('FotoPatient');

        return {
            store: store,
            patient: patient,
            v$: useVuelidate(),
        };
    },
    data: function(): any {
        return {
            selectedExternalIdOption: null,
            selectedSecondaryMeasures: null,
            episode: new Episode(),
            loading: false,
            submitted: false,
            isLoadingOptions: false,
        };
    },
    async created() {
        await this.store.Code.SetClinics();
        await this.store.Code.SetClinicians();
        await this.store.Code.SetCareTypes();
        await this.store.Code.SetPayerSource();

        const episodes = this.store.Fhir.State.EpisodeOfCare;
        if (episodes?.length === 1) this.selectedExternalIdOption = episodes[0];
    },
    methods: {
        async processSelection(codeSet: string, codeTextField: string, episodeIdField: string, episodeTextField: string, updateOptions: boolean) {
            try{
                const id = this.episode[episodeIdField];
                const code = this.store.Code.State[codeSet].filter((c: any) => c?.Id == id || c?.Value == id)[0];
                this.episode[episodeTextField] = code[codeTextField];
    
                if (updateOptions) await this.updateOptions(codeSet);
            }
            catch(er){
                Promise.reject(er);
            }
        },
        async submit() {
            try{
                this.submitted = true;
                let externalId = null;

                if(this.store.Fhir.State.EpisodeOfCare.length > 0 && this.selectedExternalIdOption?.id){
                    const existingEpisodesWithSameExternalId = this.store.Episode.FilterLocal((e: Episode) => e.ExternalEpisodeId.startsWith(this.selectedExternalIdOption.id));

                    if(existingEpisodesWithSameExternalId.length > 0)
                        externalId = `${this.selectedExternalIdOption.id}_${existingEpisodesWithSameExternalId.length}`;
                    else
                        externalId = this.selectedExternalIdOption.id;
                }
                else{ //create unique id if episodes not found/selected in epic
                    const existingEp = this.store.Episode.FilterLocal((e: Episode) => e.ExternalPatientId.startsWith(this.patient.ExternalID));
                    const existingEpWithExtIdStartingWithPatExtId = existingEp ? existingEp.length : 0;
                    const randomNum = Math.floor(Math.random() * 10000 + 1).toString();

                    externalId = `${this.patient.ExternalID}_${existingEpWithExtIdStartingWithPatExtId}_${randomNum}`;
                }

                if (!this.v$.episode.$invalid) {
                    this.loading = true;
                    this.$Progress.start();
    
                    if(this.selectedSecondaryMeasures){
                        this.episode.SecondaryBallotText = this.selectedSecondaryMeasures.map((sp: Code) => sp.Display).join(',');
                        this.episode.SecondaryMeasureIds = this.selectedSecondaryMeasures.map((sp: Code) => sp.Value);
                    }

                    this.episode.CreateDate = new Date();
                    this.episode.ExternalPatientId = this.patient.ExternalID;
                    this.episode.ExternalEpisodeId = externalId;
                    this.store.AddLog('Creating episode', this.episode);
                    
                    const response = await this.store.Episode.Add(this.episode);

                    if(!response.successful)
                        ToastService.Error(response.ErrorMessage);
                    if(response.data.ValidEpisode && response.data.EpisodeCreationResponse.EpisodeId)
                        ToastService.Success("Episode created successfully.");
                    else
                        ToastService.Error(`Error creating episode: ${response.data.InvalidEpisodeReasons.join(', ')}`);
    
                    this.$emit('close');
                    this.submitted = false;
                    this.$Progress.finish();
                    this.loading = false;
                }
            }
            catch(er){
                this.submitted = false;
                this.loading = false;
                this.$Progress.finish();
                Promise.reject(er);
            }
        },
        updateOptions: async function(updatedField: string): Promise<void> {
            
            try{
                this.isLoadingOptions = true;
                this.store.AddLog('Updating option values for episode creation, with the current values set as', this.episode);

                if (updatedField.toLocaleLowerCase() === 'caretype') {
                    this.episode.BodyPartId = null;
                    this.episode.ImpairmentId = null;
                    this.episode.PrimaryMeasureId = null;
                    this.episode.SecondaryMeasureIds = [];
                } else if (updatedField.toLocaleLowerCase() === 'bodypart') {
                    this.episode.ImpairmentId = null;
                    this.episode.PrimaryMeasureId = null;
                    this.episode.SecondaryMeasureIds = [];
                } else if (updatedField.toLocaleLowerCase() === 'impairment') {
                    this.episode.PrimaryMeasureId = null;
                    this.episode.SecondaryMeasureIds = [];
                }
    
                if (this.episode.CareTypeId !== null) {
                    await this.store.Code.SetBodyParts(this.episode.CareTypeId);
    
                    if (this.episode.BodyPartId !== null) {
                        await this.store.Code.SetImpairments(this.episode.CareTypeId, this.episode.BodyPartId);
    
                        if (this.episode.ImpairmentId !== null) 
                            await this.store.Code.SetSurgeryTypes(this.episode.BodyPartId, this.episode.ImpairmentId);
                    } 
                    else 
                        await this.store.Code.SetImpairments(this.episode.CareTypeId);
                }
    
                if (this.episode.SiteId !== null && ((this.episode.BodyPartId !== null && this.episode.BodyPartId !== -1) || (this.episode.ImpairmentId !== null && this.episode.ImpairmentId !== -1))) {
                    await this.store.Code.SetPrimaryMeasure(
                        this.episode.SiteId
                        , this.episode.CareTypeId
                        , this.episode.BodyPartId !== null ? this.episode.BodyPartId : -1
                        , this.episode.ImpairmentId !== null ? this.episode.ImpairmentId : -1,
                    );
    
                    const defaultPrimaryMeasure = await this.store.Code.GetDefaultPrimaryMeasure(
                        this.episode.SiteId
                        , this.episode.CareTypeId
                        , this.episode.BodyPartId !== null ? this.episode.BodyPartId : -1
                        , this.episode.ImpairmentId !== null ? this.episode.ImpairmentId : -1,
                    );

                    if(this.episode.PrimaryMeasureId == null && defaultPrimaryMeasure != null && this.store.Code.State.PrimaryMeasure.some(pm => pm.Value == defaultPrimaryMeasure.Value)) 
                        this.episode.PrimaryMeasureId = defaultPrimaryMeasure.Value;

                    if (this.episode.PrimaryMeasureId !== null) {
                        await this.store.Code.SetSecondaryMeasure(
                            this.episode.SiteId
                            , this.episode.CareTypeId
                            , this.episode.BodyPartId !== null ? this.episode.BodyPartId : -1
                            , this.episode.ImpairmentId !== null ? this.episode.ImpairmentId : -1
                            , this.episode.PrimaryMeasureId,
                        );

                        this.selectedSecondaryMeasures = await this.store.Code.GetDefaultSecondaryMeasureList(
                            this.episode.SiteId
                            , this.episode.CareTypeId
                            , this.episode.BodyPartId !== null ? this.episode.BodyPartId : -1
                            , this.episode.ImpairmentId !== null ? this.episode.ImpairmentId : -1
                            , this.episode.PrimaryMeasureId,
                        );
                    }
                }

                this.isLoadingOptions = false;
            }
            catch(er){
                this.isLoadingOptions = false;
                Promise.reject(er);
            }
        },
    },
    validations() {
        return {
            episode: {
                SiteId: {
                    required,
                },
                TherapistId: {
                    required,
                },
                CareTypeId: {
                    required,
                },
                PayerSourceId: {
                    required,
                },
            },
        };
    },
});
