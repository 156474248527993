import Artifact from "./artifact";
import EpisodeInfo from "./episodeInfo";

export default class Episode  
{
    EpisodeId: number = null;

    BodyPartId: number = null;
    BodyPartText: string = null;

    ImpairmentId: number = null;
    ImpairmentText: string =null;
    
    CareTypeId: number = null;
    CareTypeText: string = null;
    
    SurgeryTypeId: number = null;
    SurgeryTypeText: string =null;
    
    PayerSourceId: number = null;
    PayerSourceText: string = null;
    
    PrimaryMeasureId: number = null;
    PrimaryMeasureText: string = null;

    SiteId: number = null;
    SiteText: string = null;
    
    TherapistId: number = null;
    TherapistName: string = null;

    SecondaryMeasureIds: Array<number> = new Array<number>();
    SecondaryMeasureText: string = null;

    SurgeryDate: Date = null;
    CreateDate: Date = null;
    LatestIntakeTime: Date = null;
    LatestStatusTime: Date = null;
    DischargeTime: Date = null;
    StatusCount: number = null;
    NPId: number = null;
    NPDescription: string = null;
    ExternalEpisodeId: string = null;
    ExternalPatientId: string = null;

    Artifacts: Array<Artifact> = [];

    public DateHasValue(date: Date){
        if(![0, 1, 1969].includes(date.getFullYear()) && date !== null)
            return true;

        return false;
    }

    get Json(){
        return {
            ExternalPatientId: this.ExternalPatientId,
            ExternalEpisodeId: this.ExternalEpisodeId,
            SiteId: this.SiteId ? this.SiteId : -1,
            ClinicianId: this.TherapistId ? this.TherapistId : -1,
            CareTypeId: this.CareTypeId ? this.CareTypeId : -1,
            BodyPartId: this.BodyPartId ? this.BodyPartId : -1,
            ImpairmentId: this.ImpairmentId ? this.ImpairmentId : -1,
            SurgeryTypeId: this.SurgeryTypeId ? this.SurgeryTypeId : -1,
            SurgeryDate: this.SurgeryDate,
            PayerSourceId: this.PayerSourceId ? this.PayerSourceId : -1,
            PrimaryMeasureId: this.PrimaryMeasureId ? this.PrimaryMeasureId : -1,
            SecondaryMeasureIds: this.SecondaryMeasureIds
        };
    }

    get Info(){
        const created = new Date(this.CreateDate);
        const latestIntake = new Date(this.LatestIntakeTime);
        const latestStatus = new Date(this.LatestStatusTime);
        const discharge = new Date(this.DischargeTime);
        let latestDate = created;

        if(latestIntake > latestDate)
            latestDate = latestIntake;
        if(latestStatus > latestDate)
            latestDate = latestStatus;

        const timeDiff = new Date().getTime() - latestDate.getTime();
        const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));

        //discharged
        if(this.DateHasValue(discharge) || (this.NPId && this.NPId > 0))
            return EpisodeInfo.Discharged;

        if(dayDiff >= 30 && dayDiff <= 44)
            return EpisodeInfo.Close;

        if(dayDiff >= 45)
            return EpisodeInfo.Inactive;

        if(!this.DateHasValue(latestIntake) && dayDiff >= 7 && dayDiff < 30)
            return EpisodeInfo.IntakeOverdue;

        if(!this.DateHasValue(latestStatus) && dayDiff >= 14 && dayDiff < 30)
            return EpisodeInfo.StatusOverdue;

        return EpisodeInfo.InProgress;
    }
}