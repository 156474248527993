export enum PatientReportType {
    Intake,
    Status,
    Discharge,
    NonParticipation,
    ADLIntake,
    ADLStatus,
    MDT,
    MSIntake,
    MSStatus
}