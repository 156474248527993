import ApiService from "./apiService";
export default class Hl7Service {
  static Mdm: any = {
    Send: async (
      patientId: string,
      pFName: string,
      pLName: string,
      therapist: string,
      encounterId: string,
      externalId: string,
      description: string,
      base64: string,
      artifactJson: object
    ) => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      const dateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;

      return await ApiService.Fetch(
        new Request(`/api/Emr/Hl7/Import`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "MSH.3": "FOTO",
            "MSH.5": "FOTO",
            "MSH.6": "EPIC",
            "MSH.7": dateTime,
            "MSH.9.1": "MDM",
            "MSH.9.2": "T02",
            "MSH.10": dateTime,
            "MSH.12": "2.3",
            "PID.3.1": patientId,
            "PID.3.5": "MR",
            "PID.5.1": pLName,
            "PID.5.2": pFName,
            "PV1.1": "1",
            "PV1.19": encounterId,
            "TXA.2": "PN",
            "TXA.4": dateTime,
            "TXA.5": therapist,
            "TXA.12.3": externalId,
            "TXA.17": "AU",
            "TXA.19": "AV",
            "OBX[0].1": "1",
            "OBX[0].2": "ST",
            "OBX[0].3": "JSON",
            "OBX[0].4": "1",
            "OBX[0].5.3": "JSON",
            "OBX[0].5.5": JSON.stringify(artifactJson),
            "OBX[1].1": "2",
            "OBX[1].2": "ED",
            "OBX[1].3": "PDF_FILE",
            "OBX[1].4": "1",
            "OBX[1].5.3": "PDF",
            "OBX[1].5.4": "Base64",
            "OBX[1].5.5": base64,
            "NTE.3": description,
          }),
        })
      );
    },
  };
}
