
import { inject } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import Episode from '@/models/foto/episode';
import ToastService from '@/services/toastService';
import SurveyLaunch from '@/components/form/SurveyLaunchForm.vue';
import ArtifactDetail from '@/components/episode/artifact/Detail.vue';
import { PatientReportType } from '@/models/foto/patientReportType';
import Artifact from '@/models/foto/artifact';

export default defineComponent({
    name: 'EpisodeDetail',
    setup: function(): any {
        const store = inject('store') as any;
        const patient = (store as any).GetStateValue('FotoPatient');
		const episodesOfCare = (store as any).Fhir.State.EpisodeOfCare;

        return {
            store,
            patient,
			episodesOfCare
        };
    },
    components: {
        ArtifactDetail,
        SurveyLaunch
    },
	props: {
		_episode: Episode,
	},
    data: function(): object {
        return {
            error: null,
            artifacts: [],
            loadingDetails: false,
            updatingExternalId: false,
            inContextExternalIdOption: null,
            episode: this._episode,
            loadingDischargeDetails: false
        };
    },
    async created() {
        try{
            console.log(this._episode);
            this.$Progress.start();
            this.loadingDetails = true;

            if(this.episode.ExternalEpisodeId) {
                this.artifacts = await this.store.Artifact.ForEpisode(this.episode.ExternalEpisodeId);
    
                if(this.artifacts.length > 0)
                    this.store.SetStateValue('SelectedArtifact', this.artifacts[0]);
            }

            this.$Progress.finish();
            this.loadingDetails = false;
        }
        catch(ex: any){
            this.$Progress.finish();
            this.loadingDetails = false;
            this.error = ex;
            Promise.reject(ex);
        }
    },
    watch: {
        async 'episode.ExternalEpisodeId' (newVal){
            if(newVal){
                this.$Progress.start();
                this.loadingDetails = true;

                this.artifacts = await this.store.Artifact.ForEpisode(newVal);
    
                if(this.artifacts.length > 0)
                    this.store.SetStateValue('SelectedArtifact', this.artifacts[0]);

                this.$Progress.finish();
                this.loadingDetails = false;
            }
        }
    },
    methods: {
        getformattedDate(datetime: string) {
            return new Date(datetime).toISOString().split('T')[0].replace(/-/g, '/');
        },
        createPanelHeader(artifact: Artifact){
            return `${this.getformattedDate(artifact.SurveyDateTimeCompleted)} Functional ${PatientReportType[artifact.ReportType].toString()} Summary`
        },
        toggleExtEpisodeIdOp(event: any) {
            this.$refs.extEpisodeIdOp.toggle(event);
        },
        artifactSelected(event: any){
            this.store.SetStateValue('SelectedArtifact', this.artifacts[event.index]);
        },
		async submitExternalEpisodeIdEdit() {
            try {
                if (this.episode.ExternalEpisodeId !== this.inContextExternalIdOption?.id && this.inContextExternalIdOption){
					let externalId = this.inContextExternalIdOption?.id;
                    
					const existingEp = this.store.Episode.FilterLocal((e: Episode) => e.ExternalEpisodeId.startsWith(externalId));
                    if(existingEp && existingEp.length > 0) 
						externalId += `_${existingEp.length}`;

					this.store.AddLog(`Updating external id for episode ${this.episode.EpisodeId} with the new value of ${externalId}`);
                    this.$Progress.start();
                    this.updatingExternalId = true;
    
                    const result = await this.store.Episode.DeleteClaim(this.episode.EpisodeId, this.episode.ExternalEpisodeId);
    
                    if (result.successful){
                        const response = await this.store.Episode.Claim(this.episode.EpisodeId, externalId);

						if(response.successful && response.data.Success)
							ToastService.Success("Episode claimed successfully.");
						else if(!response.successful)
							ToastService.Error(`Unable to update external id: ${response.errorMessage}`);
						else
							ToastService.Error(`Unable to update external id: ${response.data.Text}`);
					}
					else
						ToastService.Error(`Unable to update external id: ${result.errorMessage}`);
    
                    this.updatingExternalId = false;
                    this.$Progress.finish();
                }
            } 
			catch (e) {
				this.$Progress.finish();
                this.updatingExternalId = false;
				Promise.reject(e);
            }
        },
        async displayDischarge(){
            this.loadingDischargeDetails = true;

            const items_for_close = await this.store.Episode.GetCloseItems(this.episode.ExternalEpisodeId);
            this.$emit('DisplayDischarge', this.episode, items_for_close);
            
            this.loadingDischargeDetails = false;
        },
		async removeDischarge(){
			try{
				this.$Progress.start();
				this.store.AddLog(`Removing discharge of episode with id of ${this.episode.EpisodeId}`);
				
				const response = await this.store.Episode.DeleteDischarge(this.episode.ExternalEpisodeId);
	
				if(!response.successful)
					ToastService.Error(response.errorMessage);        
				else if(response.data.ResultCode === 0)
					ToastService.Error(response.data.Text);
				else if(response.data.ResultCode === 1)
					ToastService.Warning(response.data.Text);
				else
					ToastService.Success("Success! Episode is no longer discharged.");
				
				this.store.AddLog(`Discharge removal completed.`);
				this.$Progress.finish();
			}
			catch(e: any){
				this.$Progress.finish();
				Promise.reject(e);
			}
		},
    },
});
