import Discharge from "@/models/foto/discharge";
import Episode from "@/models/foto/episode";
import ItemsForClose from "@/models/foto/itemsForClose";
import SurveyDetails from "@/models/foto/surveyDetails";
import NonParticipationIncompleteIntake from "@/models/foto/nonParticipationIncompleteIntake";
import NonParticipationNoStatus from "@/models/foto/nonParticipationNoStatus";
import Visit from "@/models/foto/visit";
import EpisodeService from "@/services/foto/episodeService";
import { reactive, readonly } from "vue";

export default class EpisodeStore {
    private _episodeState: any = reactive({
        Episodes: [] as Array<Episode>
    });

    private readonly _episodeService: EpisodeService;

    constructor(){
        this._episodeService = new EpisodeService();
    }

    public State: any = readonly(this._episodeState);
    
    private Get(episodeId: Number) : Episode{
        return this._episodeState.Episodes.filter((e: Episode) => e.EpisodeId == episodeId)[0];
    }

    private GetByExternalId(externalEpisodeId: string) : Episode{
        return this._episodeState.Episodes.filter((e: Episode) => e.ExternalEpisodeId == externalEpisodeId)[0];
    }

    FilterLocal(filterFunc: Function){
        return this._episodeState.Episodes.filter((e: Episode) => filterFunc(e));
    }

    async DeleteClaim(episodeId: number, externalEpisodeId: string){
        return await this._episodeService.DeleteClaim(this.Get(episodeId), externalEpisodeId);
    }

    async GetCloseItems(externalEpisodeId: string): Promise<ItemsForClose[]> {
        const episode = this.GetByExternalId(externalEpisodeId);

        return await this._episodeService.GetCloseItems(episode.EpisodeId);
    }

    async DeleteDischarge(externalEpisodeId: string){
        return await this._episodeService.DeleteDischarge(this.GetByExternalId(externalEpisodeId));
    }

    async DeleteVisit(visitId: number){
        return await this._episodeService.DeleteVisit(visitId);
    }

    async Claim(episodeId: number, externalEpisodeId: string){
        return await this._episodeService.Claim(this.Get(episodeId), externalEpisodeId);
    }

    async SetPatientEpisodes(externalPatientId: string){
        this._episodeState.Episodes = (await this._episodeService.GetAll(externalPatientId)).map((e: Episode) => Object.assign(new Episode(), e));
    }

    async GetSurvey(episodeId: string | Number) : Promise<SurveyDetails>{
        return await this._episodeService.GetSurvey(episodeId.toString());
    }

    async AddVisit(visit: Visit) : Promise<any>{
        return await this._episodeService.AddVisit(visit);
    }

    async NonParticipationIncompleteIntake(npii: NonParticipationIncompleteIntake): Promise<any>{
        const result = await this._episodeService.NonParticipationIncompleteIntake(npii);

        if(result.successful && result.data.ResultCode == 1){
            const episode = this.Get(npii.EpisodeId);
            episode.DischargeTime = new Date();
            episode.NPId = npii.ReasonId;
        }

        return result;
    }
    
    async NonParticipationNoStatus(npnn: NonParticipationNoStatus): Promise<any>{
        const result = await this._episodeService.NonParticipationNoStatus(npnn);
        
        if(result.successful && result.data.ResultCode == 1){
            const episode = this.Get(npnn.EpisodeId);
            episode.NPId = npnn.ReasonId;
        }

        return result;
    }

    async Add(episode: Episode) : Promise<any>{
        const result: any = await this._episodeService.Create(episode);

        if(result.successful && result.data.ValidEpisode && result.data.EpisodeCreationResponse.EpisodeId){
            episode.EpisodeId = result.data.EpisodeCreationResponse.EpisodeId;
            this._episodeState.Episodes.unshift(episode);
        }

        return result;
    }

    async Discharge(discharge: Discharge) : Promise<any>{
        return await this._episodeService.Discharge(this.GetByExternalId(discharge.ExternalEpisodeId), discharge);
    }

    async DischargeV2(discharge: Discharge) : Promise<any>{
        return await this._episodeService.DischargeV2(this.Get(discharge.EpisodeId), discharge);
    }

    async GetTotalVisits(episodeId: number) : Promise<any>{
        return await this._episodeService.GetTotalVisits(episodeId);
    }
}
