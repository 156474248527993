import Code from '@/models/code';
import Clinic from '@/models/foto/clinic';
import Clinician from '@/models/foto/clinician';
import ApiService from '@/services/apiService';

export default class CodeService {
    async GetCodes(listName: string) : Promise<Array<Code>>{
        const response = await ApiService.FetchAndParseResponseData<Array<any>>(new Request(`/api/Foto/episode/${listName}`, { method: 'GET' }), true, 12);
        return response.map((code: any) => new Code(code.Key, code.Value));
    }

    async GetDefaultPrimaryMeasure(params: string) : Promise<Code>{
       const result = await ApiService.FetchAndParseResponseData<any>(new Request(`/api/Foto/episode/GetDefaultPrimaryMeasure/${params}`, { method: 'GET' }), true, 12);

       if(result != null)
            return new Code(result.Key, result.Value);

        return null;
    }

    async GetClinics() : Promise<Array<Clinic>>{
        return await ApiService.FetchAndParseResponseData<Array<any>>(new Request(`/api/Foto/clinic/json/`, { method: 'GET' }), true, 12);
    }

    async GetClinicians() : Promise<Array<Clinician>>{
        return await ApiService.FetchAndParseResponseData<Array<any>>(new Request(`/api/Foto/clinician/json/`, { method: 'GET' }), true, 12);
    }
}
