import Patient from '@/models/foto/patient';
import ApiResponse from '@/models/api/apiResponse';
import ApiService from '@/services/apiService';

export default class PatientService {
    async Create(patient: Patient): Promise<ApiResponse> {
        const resp = await ApiService.Fetch(new Request(`/api/Foto/patient2/json`, {
            method: 'POST',
            body: patient.ToJSON()
        }));

        if(resp.successful)
            return resp;
        
        resp.data.Message = "Error creating or updating patient";
        throw resp;
    }
}
