import Artifact from "@/models/foto/artifact";
import Email from "@/models/foto/email";
import Episode from "@/models/foto/episode";
import ArtifactService from "@/services/foto/artifactService";
import { reactive, readonly } from "vue";

export default class ArtifactStore {
    private _artifactState: any = reactive({
        Artifacts: [] as Array<Episode>
    });
    
    public State: any = readonly(this._artifactState);

    private readonly _artifactService: ArtifactService;

    constructor(){
        this._artifactService = new ArtifactService();
    }
    
    async ForEpisode(externalEpisodeId: string){
        this._artifactState.Artifacts = await this._artifactService.GetArtifacts(externalEpisodeId);
        return this.State.Artifacts;
    }

    async SetVisit(reportId: number, value: number){
        const artifact = this._artifactState.Artifacts.filter((a: Artifact) => a.ReportId === reportId)[0];
        return await this._artifactService.SetVisit(artifact, value);
    }

    async GetReport(reportId: number){
        const artifact = this._artifactState.Artifacts.filter((a: Artifact) => a.ReportId === reportId)[0];
        return await this._artifactService.GetReport(artifact);
    }

    async EmailSurveyInformation(email: Email){
        return await this._artifactService.Email(email);
    }
}
