import Artifact from '@/models/foto/artifact';
import Email from '@/models/foto/email';
import ApiService from '@/services/apiService';

export default class ArtifactService {
    async GetReport(artifact: Artifact): Promise<Array<any>> {
        return await ApiService.FetchAndParseResponseData<Array<any>>(new Request(`/api/Foto/report/json/${artifact.ReportId}/${artifact.ReportType}`, { method: 'GET' }), true, 168);
    }

    async GetArtifacts(externalEpisodeId: string) : Promise<Array<Artifact>> {
        const artifacts: Array<Artifact> = await ApiService.FetchAndParseResponseData<Array<Artifact>>(new Request(`/api/Foto/artifact2/json/episode/${externalEpisodeId}`, { method: 'GET' }), true, 12);
        return artifacts.map((a: Artifact) => Object.assign(new Artifact(), a)).sort((a: Artifact, b: Artifact) => b.SurveyDateTimeCompleted.localeCompare(a.SurveyDateTimeCompleted));
    }

    async SetVisit(artifact: Artifact, visit: number): Promise<any> {
        const response = await ApiService.Fetch(new Request(`/api/Foto/episode/ArtifactVisit`, {
            method: 'POST',
            body: JSON.stringify({
                ExternalEpisodeId: artifact.ExternalEpisodeId,
                ReportId: artifact.ReportId,
                VisitNumber: visit
            })
        }));
            
        if(response.successful){
            artifact.Visit = visit;
            await ApiService.DeleteCacheItem(`/api/Foto/artifact2/json/episode/${artifact.ExternalEpisodeId}`)
        }

        return response;
    }

    async Email(email: Email){
        return await ApiService.Fetch(new Request(`/api/Foto/Survey/Email`, {
            method: 'POST',
            body: JSON.stringify(email)
        }));
    }
}