import { FunctionalAssessment } from "./functionalAssessment";
import { PatientReported } from "./patientReported";
import { PatientReportType } from "./patientReportType";
import { PQRSClaimsReportingCode } from "./pqrsClaimsReportingCode";
import { RiskAdjustedCriteria } from "./riskAdjustedCriteria";

export default class Artifact 
{
    EpisodeId: number;
    ExternalEpisodeId: string;
    FunctionalAssessments: FunctionalAssessment[];
    PatientReportedItems: PatientReported[];
    PQRSClaimsReportingCodes: PQRSClaimsReportingCode[];
    ReportId: number;
    ReportType: PatientReportType;
    RiskAdjustedItems: RiskAdjustedCriteria[];
    SurveyDateTimeCompleted: string;
    SurveyDateTimeStarted: string;
    Visit: number;
}