import ApiService from '@/services/apiService';
import AppError from '../components/AppError.vue';
import { createApp } from 'vue';
import ApplicationStore from '../stores/applicationStore';
import { createVueApp } from './createApp';

(async () => {
	const params = new URLSearchParams(window.location.search);
	let _loggingTimeout = false;
	let _appStore = null;

	function Redirect(errObj: object){
		const guid = 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});

		localStorage.setItem(`ERROR_${guid}`, JSON.stringify(errObj));
		window.location.href = `?error=${guid}`;
	}

	function OnError(error:any){
		let postData = null, user = null, err_obj = null, location = null, additional_data;

		if(error.stackTrace || error.errorMessage || error.endpoint || error.data){
			err_obj = {
				Message: error.errorMessage,
				StackTraceString: error.stackTrace,
				Endpoint: error.endpoint,
				Data: error.data
			}
		}
		else{
			err_obj = {
				Message: error.message,
				StackTraceString: error.stack,
			}
		}

		if(_appStore){
			_appStore.SetContextValue('ErrorMessage', err_obj.Message);
			_appStore.SetContextValue('ErrorStackTrace', err_obj.StackTraceString);
			
			if(err_obj.Endpoint)
				_appStore.SetContextValue('ErrorUrl', err_obj.Endpoint);

			if(err_obj.Data)
				_appStore.SetContextValue('ErrorData', err_obj.Data);

			location = _appStore.Context.LocationName;
			user = _appStore.Context.UserName;
			postData = _appStore.Context;
		}
		else{
			postData = err_obj;
			user = `launch_${new Date().toISOString().slice(-24).replace(/\D/g,'').slice(0, 14)}`;
		}

		console.log(error);

		if(!_loggingTimeout){
			fetch(new Request(`/api/Logging/Error?user=${user}&location=${location}`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(postData)
			}));
			
			_loggingTimeout = true;
			setTimeout(() => _loggingTimeout = false, 10000);
		}

		if(!_appStore || !_appStore.HasFinishedLaunch)
			Redirect(err_obj);
	}

	const error = params.get("error");
	window.addEventListener('unhandledrejection', (event) => OnError(event.reason));

	if(!error){
		const code = params.get("code");
		const state = params.get("state");
		const launchedByResource = localStorage.getItem("SESSION_RESOURCE");
		localStorage.removeItem("SESSION_RESOURCE");
	
		const launch_response = await ApiService.Fetch(new Request(`/api/Emr/Token?&code=${code}&state=${state}&launchedByResource=${launchedByResource}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}));
	
		console.log(launch_response);
		
		if(launch_response.successful){
			_appStore = new ApplicationStore(launch_response.data);
			
			const app = createVueApp();
			app.config.errorHandler = (error) => OnError(error as Error);
			app.provide('store', _appStore);
			app.mount('#app');
		}
		else 
			throw launch_response;
	}
	else{
		const app = createApp(AppError);
		app.mount('#app');
	}

})();
