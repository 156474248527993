
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    name: 'AppError',
    data: function(): any {
        return {
            error: null,
        };
    },
    async created() {
        const urlParams = new URLSearchParams(window.location.search);
        const err_num = urlParams.get("error");
        this.error = JSON.parse(localStorage.getItem(`ERROR_${err_num}`));
        localStorage.removeItem(`ERROR_${err_num}`);
    },
});
