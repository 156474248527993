import Clinician from "@/models/foto/clinician";
import CodeService from "@/services/foto/codeService";
import { reactive, readonly } from "vue";

export default class CodeListStore {
    private _codeState: any = reactive(
        {
            PrimaryMeasure: [],
            SecondaryMeasure: [],
            CareType: [],
            PayerSource: [],
            BodyPart: [],
            Impairment: [],
            SurgeryType: [],
            Clinic: [],
            Clinician: []
        }
    );
    
    State: any = readonly(this._codeState); 

    private readonly _codeService: CodeService;

    constructor(){
        this._codeService = new CodeService();
    }

    async SetCareTypes() {
        this._codeState.CareType = await this._codeService.GetCodes("GetCareTypeList/");
    }

    async SetPayerSource(){
        this._codeState.PayerSource = await this._codeService.GetCodes("GetPayerSourceList/");
    }

    async SetBodyParts(careTypeId: string){
        this._codeState.BodyPart = await this._codeService.GetCodes(`GetBodyPartList/${careTypeId}`);
    }

    async SetImpairments(careTypeId: string, bodyPartId: string | null = null){
        this._codeState.Impairment = await this._codeService.GetCodes(bodyPartId ? `GetImpairmentList/${careTypeId}/${bodyPartId}` : `GetImpairmentList/${careTypeId}`);
    }

    public async SetSurgeryTypes(bodyPartId: string, impairmentId: string){
        this._codeState.SurgeryType = await this._codeService.GetCodes(`GetSurgeryTypeList/${bodyPartId}/${impairmentId}`);
    }

    async SetClinics(){
        this._codeState.Clinic = await this._codeService.GetClinics();
    }

    async SetClinicians(){
        this._codeState.Clinician = (await this._codeService.GetClinicians()).map(c => Object.assign(new Clinician(), c));
    }

    async SetPrimaryMeasure(siteId: string, careTypeId: string, bodypartId: string, impairmentId: string){
        this._codeState.PrimaryMeasure = await this._codeService.GetCodes(`GetPrimaryMeasureList/${siteId}/${careTypeId}/${bodypartId}/${impairmentId}`);
    }

    async GetDefaultPrimaryMeasure(siteId: string, careTypeId: string, bodypartId: string, impairmentId: string){
        return await this._codeService.GetDefaultPrimaryMeasure(`${siteId}/${careTypeId}/${bodypartId}/${impairmentId}`);
    }

    async GetDefaultSecondaryMeasureList(siteId: string, careTypeId: string, bodypartId: string, impairmentId: string, primaryMeasureId:string){
        return await this._codeService.GetCodes(`GetDefaultSecondaryMeasureList/${siteId}/${careTypeId}/${bodypartId}/${impairmentId}/${primaryMeasureId}`);
    }

    async SetSecondaryMeasure(siteId: string, careTypeId: string, bodypartId: string, impairmentId: string, primaryMeasureId:string){
        this._codeState.SecondaryMeasure = await this._codeService.GetCodes(`GetSecondaryMeasureList/${siteId}/${careTypeId}/${bodypartId}/${impairmentId}/${primaryMeasureId}`);
    }
}
