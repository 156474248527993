
import { defineComponent } from '@vue/runtime-core';
import { EpisodeOfCare, Identifier, List } from 'fhir/r4';
import { inject } from 'vue';
import { getExtensionValue } from '@/utilities/r4-ext-utils';

export default defineComponent({
    name: 'AppSidebar',
    setup: function(): any {
        return {
            d_store: inject('store'),
        };
    },
    data() {
        return {
            lists: [
                {
                    name: 'Allergies',
                    data: (this.d_store as any).Fhir.State.Allergies?.filter((a: List) => a.status === 'current'),
                    useFullRow: false,
                },
                {
                    name: 'Immunizations',
                    data: (this.d_store as any).Fhir.State.Immunizations?.filter((a: List) => a.status === 'current'),
                    useFullRow: false,
                },
                {
                    name: 'Medications',
                    data: (this.d_store as any).Fhir.State.Medications?.filter((a: List) => a.status === 'current'),
                    useFullRow: false,
                },
                {
                    name: 'Problems',
                    data: (this.d_store as any).Fhir.State.Problems?.filter((a: List) => a.status === 'current'),
                    useFullRow: true,
                },
            ],
            patient: (this.d_store as any).Fhir.State.Patient,
            patient_name: null
        };
    },
    mounted() {
        let patientName = this.patient?.name.filter(n => n.use == 'official');
        this.patient_name = patientName.length > 0 ? patientName[0].text : this.patient?.name[0]?.text;

        this.lists.forEach((list: any) => {
            if (list.data && list.data[0]?.entry?.length > 1) this.generateListTableBody(list.name, list.data[0].entry, list.useFullRow);
        });
    },
    computed: {
        age(){
            const today = new Date();
            const birthDate = new Date(this.patient?.birthDate);
            const age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();

            return m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ? age - 1 : age;
        },
        mrn(){
            let mrn_val = this.getIdentifierValue("mrn");

            if(!mrn_val || mrn_val == "")
                mrn_val = this.getIdentifierValue("mr");

            if(!mrn_val || mrn_val == "")
                mrn_val = this.getIdentifierValue("epi");

            return mrn_val;
        },
        demographics(){
            return [
                {
                    key: 'Birth Day',
                    value: this.patient?.birthDate
                },
                {
                    key: 'Alias',
                    value: this.mrn
                },
                {
                    key: 'Birth Sex',
                    value: this.getExtensionValue('us-core-birthsex')
                },
                {
                    key: 'Race',
                    value: this.getExtensionValue('us-core-race')
                },
                {
                    key: 'Ethnicity',
                    value: this.getExtensionValue('us-core-ethnicity')
                }
            ];
        },
        encounter(){
            const encounter = this.d_store.Fhir.State.Encounter;
            const encounterDetails = [];
            let period = encounter?.period.start;

            if (encounter?.period.end) period += ` - ${encounter.period.end}`;

            if (period) {
                encounterDetails.push({
                    key: 'Period',
                    value: period,
                });
            }

            if (encounter?.status) {
                encounterDetails.push({
                    key: 'Status',
                    value: this.capitalizeFirstLetter(encounter.status),
                });
            }

            if (encounter?.type.length > 0) {
                encounterDetails.push({
                    key: 'Type',
                    value: encounter.type[0]?.text,
                });
            }

            if (encounter?.location.length > 0) {
                encounterDetails.push({
                    key: 'Location',
                    value: encounter.location[0].location.display,
                });
            }

            return encounterDetails;
        }
    },
    methods: {
        generateListTableBody(listName: string, data: any, useFullRow: boolean = false) {
            let value = '<tr role="row" colspan="12">';
            const items = [...new Set(data.slice().sort((a: any, b: any) => a.item.display.localeCompare(b.item.display)).map((a: any) => a.item.display))];

            if (!useFullRow) {
                let count = 0;

                items.forEach((item: any) => {
                    if (count % 2 === 0) value += `<td role="cell" colspan="6">${item}</td>`;
                    else value += `<td role="cell" colspan="6">${item}</td></tr>`;

                    count++;
                });

                if (items && items.length !== 0 && items.length % 2 !== 0) value += "<td role='cell' colspan='6'></td></tr>";
                else value += '</tr>';
            } 
            else items.forEach((item: any) => { value += `<tr><td role="cell">${item}</td></tr>`; });

            const tBody: HTMLElement = document.querySelector(`#list-table-body-${listName}`);
            if (tBody) tBody.innerHTML = value;
        },
        populateEpisodeDetails(episode: EpisodeOfCare) {
            let episodeDetails = [];
            const type = episode?.type?.map((t: any) => `${t.text}`).join(', ');
            const condition = episode?.diagnosis?.map((t: any) => `${t.condition.display}`).join(', ');

            if (episode?.status) {
                episodeDetails.push({
                    key: 'Status',
                    value: this.capitalizeFirstLetter(episode?.status),
                });
            }

            if (condition) {
                episodeDetails.push({
                    key: 'Condition',
                    value: condition,
                });
            }

            if (type) {
                episodeDetails.push({
                    key: 'Type',
                    value: type,
                });
            }

            return episodeDetails;
        },
        getIdentifierValue(key: string) {
            const id = this.patient?.identifier.filter((i: Identifier) => i.type?.text?.toLowerCase() === key);
            return id && id.length > 0 ? id[0].value : '--';
        },
        getExtensionValue(key: string) {
            return getExtensionValue(this.patient, key, true) || '--';
        },
        capitalizeFirstLetter(string: string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
});
