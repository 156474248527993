export default class Clinician {
    FirstName: string;
    LastName: string;
    Alias: string;
    Credentials: string;
    Email: string;
    Id: number;
    NPI: string;
    Qualifications: string;
    Enabled: boolean;

    get Name(){
        return `${this.FirstName} ${this.LastName}`;
    }
}