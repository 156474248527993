
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';
import ApplicationStore from '../../stores/applicationStore';
import Code from '../../models/code';
import { BundleEntry, Coding, Resource } from 'fhir/r4';
import Episode from '../../models/foto/episode';
import ToastService from '../../services/toastService';

export default defineComponent({
    name: 'ClaimEpisodeForm',
    setup() {
        const store = inject('store') as ApplicationStore
        const codes = store.Fhir.State.ValueSet.EpisodeOfCareType?.expansion?.contains;
        let types: Array<any> = [];

        if (codes && codes.length > 0)
            types = [...codes].sort((a: any, b: any) => a.display.localeCompare(b.display)).map((c: Coding) => new Code(`${c.system}|${c.code}`, `${c.display} (${c.code})`));

        return {
            store,
            types
        };
    },
    props: {
        episodeId: Number,
    },
    data: function(): any {
        return {
            loading: false,
            submitted: false,
            selectedType: null,
            selectedEpisode: null,
            episodeOptions: [],
        };
    },
    methods: {
        async loadEpisodes() {
            try{
                this.submitted = false;
                this.selectedEpisode = null;
                const options = await this.store.Fhir.EpisodeSearch(this.selectedType);
                this.episodeOptions = options.entry.map((e: BundleEntry) => e.resource).filter((e: Resource) => e.resourceType.toLowerCase() !== 'operationoutcome');
            }
            catch(er){
                Promise.reject(er);
            }
        },
        async submit() {
            this.submitted = true;

            try{
                if (this.selectedEpisode) {
                    this.store.AddLog(`Claiming episode id of ${this.episodeId} with the value of ${this.selectedEpisode.id}`);
                    this.loading = true;
                    this.$Progress.start();
    
                    const existingEp = this.store.Episode.FilterLocal((e: Episode) => { e.ExternalEpisodeId.startsWith(this.selectedEpisode.id) });
                    if(existingEp && existingEp.length > 0) this.selectedEpisode.id += `_${existingEp.length}`;

                    const response = await this.store.Episode.Claim(this.episodeId, this.selectedEpisode.id);
    
                    if(response.successful && response.data.Success)
                        ToastService.Success("Episode claimed successfully.");
                    else if(!response.successful)
                        ToastService.Error(`Unable to claim episode: ${response.errorMessage}`);
                    else
                        ToastService.Error(`Unable to claim episode: ${response.data.Text}`);

                    this.loading = false;
                    this.$emit('close');
                    this.$Progress.finish();
                }
            }
            catch(er){
                this.$Progress.finish();
                this.submitted = false;
                this.loading = false;
                Promise.reject(er);
            }
        },
    },
});
