
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';
import ApplicationStore from '../../../stores/applicationStore';
import ToastService from '@/services/toastService';
import NonParticipationIncompleteIntake from '@/models/foto/nonParticipationIncompleteIntake';

export default defineComponent({
    name: 'IntakeNonParticipationForm',
    setup() {
        return {
            store: inject('store') as ApplicationStore,
            v$: useVuelidate(),
        };
    },
    props: {
        episodeId: Number,
        incompleteIntakeReasonList: Array
    },
    data: function(): any {
        return {
            incomplete_intake: new NonParticipationIncompleteIntake(),
            submitted: false,
            loading: false,
        };
    },
    methods: {
        async submit() {
            try{
                this.submitted = true;

                if (!this.v$.incomplete_intake.$invalid) {
                    this.store.AddLog('Discharging episode', this.episodeId);
                    this.loading = true;
                    this.$Progress.start();
                    
                    this.incomplete_intake.EpisodeId = this.episodeId;
                    const response = await this.store.Episode.NonParticipationIncompleteIntake(this.incomplete_intake);

                    if(response.successful && response.data.ResultCode === 1) 
                        ToastService.Success("Episode closed successfully.");
                    else if(!response.successful) 
                        throw new Error(`Error closing episode: ${response.errorMessage}`);
                    else if(response.data.ResultCode === 0) {
                        if(response.data.Errors.length > 0)
                            ToastService.Warning(response.data.Errors.join(', '));
                        else
                            throw new Error(`Error closing episode: ${response.data.Text}`);
                    }
                    else if(response.data) 
                        ToastService.Warning(response.data.Errors.join(', '));

                    this.submitted = false;
                    this.loading = false;
                    this.$emit('close');
                    this.$Progress.finish();
                }
            }
            catch(er){
                this.submitted = false;
                this.loading = false;
                this.$Progress.finish();
                
                Promise.reject(er);
            }
        },
    },
    validations() {
        return {
            incomplete_intake: {
                EvalDate: {
                    required,
                },
                ReasonId: {
                    required
                },
                Explanation: {
                    required: requiredIf(function() {
                        return this.incomplete_intake.ReasonId == 93
                    })
                }
            },
        };
    },
});
