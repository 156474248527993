import PatientVisitHours from "./patientVisitHours";

export default class Discharge {
    ClinicianComments: string = '';
    EpisodeId: number;
    ExternalEpisodeId: string;
    InterruptionDays: number;
    LastVisit: Date | null = null;
    MIPSExceptionsExclusions: string;
    NoStatusReasonId: number;
    SurgeryTypeId: number;
    WhoDischarged: string;
    GlobalRating: number;
    Goals: string;
    PatientVisitsHours: PatientVisitHours = new PatientVisitHours();
}