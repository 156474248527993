export default class ToastService {
    static Success(message: string, length: number = 10000){
        (<any>Window).$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: length,
        });
    }

    static Warning(message: string, length: number = 10000){
        (<any>Window).$toast.add({
            severity: 'warn',
            summary: 'Warning',
            detail: message,
            life: length,
        });
    }

    static Error(message: string, length: number = 20000){
        (<any>Window).$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: length,
        });
    }
}
