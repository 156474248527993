<template>
	<div class="layout-logo">
		<img alt="Logo" src="../assets/FOTO-Analytics-horizontal-white.png" />
	</div>
	<div class="layout-topbar">
		<div class="layout-topbar-icons">
			<button class="p-link">
				<Avatar v-tooltip.left="`Welcome${firstName ? ', ' + firstName : ''}`" icon="pi pi-user" size="large"
					:label="initials" />
			</button>
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';

export default defineComponent({
	name: 'AppTopBar',
	setup() {
		const store = inject('store');
		const practitioner = store.Fhir.State.Practitioner;
		let firstName = null;
		let initials = null;

		if (practitioner?.name.length > 0) {
			const lastName = practitioner?.name[0].family;

			if (practitioner?.name[0].given?.length > 0) firstName = practitioner.name[0].given[0];

			if (firstName) initials = firstName[0];
			if (lastName) initials += lastName[0];
		}
		else {
			firstName = store.GetContextValue("UserName");

			if (firstName)
				initials = firstName.slice(0, 2);
		}

		return {
			firstName,
			initials
		};
	},
});
</script>