
import { defineComponent } from '@vue/runtime-core';
import { PropType } from 'vue';
import { FunctionalAssessment } from '@/models/foto/functionalAssessment';

export default defineComponent({
    name: 'ArtifactSummaryChart',
    props: {
        primaryAssessment: Object as PropType<FunctionalAssessment>,
    },
    data() {
        return {
            lineOptions: {
                interaction: {
                    mode: 'point',
                },
                plugins: {
                    legend: {
                        position: 'top',
                        fullSize: false,
                        labels: {
                            boxHeight: 5,
                            boxWidth: 20,
                        },
                    },
                },
            },
        };
    },
    computed: {
        chartData() {
            let mciiVal = this.primaryAssessment.MCII;
            let mdcVal = this.primaryAssessment.MDC;
            let predicted = this.primaryAssessment.PredictedChangeSummary.FunctionalScoreGoal;
            let fotomean = this.primaryAssessment.RiskAdjustedStatisticalFOTO;

            const predictedLineVals: Array<number> = [];
            const mciiVals: Array<number> = [];
            const mdcVals: Array<number> = [];
            const labelVals: Array<string> = [''];
            const meanVals: Array<number> = [fotomean];
            const functionalScores: Array<number> = [null];
            const mdcRadius: Array<number> = [2];
            const mciiRadius: Array<number> = [2];
            const predictedRadius: Array<number> = [null];

            for (let index = 0; index < this.primaryAssessment.FunctionalScoreSummaries.length; index++) {
                const score = this.primaryAssessment.FunctionalScoreSummaries[index];

                if (score.Label.toLowerCase() === 'intake') {
                    mciiVal += score.FunctionalScore;
                    mdcVal += score.FunctionalScore;
                }
            
                mdcRadius.push(null);
                mciiRadius.push(null);
                predictedLineVals.push(null);
                mciiVals.push(mciiVal);
                mdcVals.push(mdcVal);
                labelVals.push(score.Label);
                functionalScores.push(score.FunctionalScore);
                meanVals.push(fotomean);

                if (index === this.primaryAssessment.FunctionalScoreSummaries.length - 1) {
                    predictedLineVals.push(score.FunctionalScore);
                    predictedLineVals.push(predicted);
                    labelVals.push('Predicted', '');
                    mciiVals.push(mciiVal, mciiVal, mciiVal);
                    mdcVals.push(mdcVal, mdcVal, mdcVal);
                    mciiRadius.push(null, 2);
                    mdcRadius.push(null, 2);
                    meanVals.push(fotomean, fotomean);
                    predictedRadius.push(0, 9);
                }
                else
                    predictedRadius.push(null);
            }

            return {
                labels: labelVals,
                datasets: [
                    {
                        label: 'Patient Actual',
                        type: 'line',
                        radius: 2,
                        hoverRadius: 2,
                        borderWidth: 1.5,
                        data: functionalScores,
                        fill: false,
                        backgroundColor: 'green',
                        borderColor: 'green',
                        pointStyle: 'circle',
                    },
                    {
                        label: 'MCII',
                        type: 'line',
                        data: mciiVals,
                        fill: false,
                        radius: mciiRadius,
                        hoverRadius: 4,
                        borderWidth: 1.5,
                        borderColor: 'blue',
                        pointStyle: 'circle',
                        borderDash: [5, 5],
                    },
                    {
                        label: 'MDC',
                        type: 'line',
                        data: mdcVals,
                        fill: false,
                        radius: mdcRadius,
                        hoverRadius: 4,
                        borderWidth: 1.5,
                        borderColor: 'purple',
                        pointStyle: 'circle',
                        borderDash: [5, 5],
                    },
                    {
                        label: 'FOTO Predicted',
                        type: 'line',
                        radius: predictedRadius,
                        hoverRadius: 8,
                        borderWidth: 1.5,
                        pointHitRadius: 0,
                        data: predictedLineVals,
                        backgroundColor: 'orange',
                        borderColor: 'orange',
                        pointStyle: 'star',
                        borderDash: [5, 5],
                    },
                    {
                        label: 'FOTO Mean',
                        type: 'line',
                        radius: 2,
                        borderWidth: 1.5,
                        hoverRadius: 4,
                        data: meanVals,
                        fill: false,
                        borderColor: 'red',
                        backgroundColor: '#fef4f7',
                        pointStyle: 'circle',
                    },
                ],
            };
        },
    },
});
