import ApiResponse from '@/models/api/apiResponse';
import ApiService from '@/services/apiService';

export default class SsoService {
    static async Launch(username: string, externalPatientId: string, externalEpisodeId: string): Promise<ApiResponse> {
        const resp = await ApiService.Fetch(new Request(`/api/Sso/partneraccess/json/EpisodeDetails/${username}/${externalPatientId}/${externalEpisodeId}`, {
            method: 'GET',
        }));

        if(resp.successful)
            return resp;
        
        resp.data.Message = "Unable to proceed with single sign on. Please ensure your epic username is the same as your foto username.";
        throw resp;
    }
}