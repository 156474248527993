
import { inject } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import { FilterMatchMode } from 'primevue/api';
import Episode from '@/models/foto/episode';
import EpisodeInfo from '@/models/foto/episodeInfo';
import ItemsForClose from '@/models/foto/itemsForClose';
import EpisodeDetail from '@/components/episode/Detail.vue';
import SsoService from '@/services/foto/ssoService';

export default defineComponent({
    name: 'AppDash',
    setup: function(): any {
        const store = inject('store') as any;
        const patient = (store as any).GetStateValue('FotoPatient');

        return {
            store,
            patient,
        };
    },
	components: {
        EpisodeDetail,
    },
	props: {
		showDischarged: Boolean,
		activeEpisodeFilters: Array
	},
    data: function(): object {
        return {
			d_showDischarged: this.showDischarged,
			expandedRows: [],
            d_activeEpisodeFilters: this.activeEpisodeFilters,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
			cards: [
                {
                    InfoID: 0,
                    ContainerClass: "inProgress",
                    Title: "In Progress",
                    Display: "Open Episodes",
                    ValueContainerBgClass: "bg-green-100",
                    ValueContainerFcClass: "text-green-500"
                },
                {
                    InfoID: 1,
                    ContainerClass: "intakeOverdue",
                    Title: "Intake Overdue",
                    Display: "7 to 14 days of inactivity",
                    ValueContainerBgClass: "bg-blue-100",
                    ValueContainerFcClass: "text-blue-500"
                },
                {
                    InfoID: 2,
                    ContainerClass: "statusOverdue",
                    Title: "Status Overdue",
                    Display: "14 to 30 days of inactivity",
                    ValueContainerBgClass: "bg-purple-100",
                    ValueContainerFcClass: "text-purple-500"
                },
                {
                    InfoID: 3,
                    ContainerClass: "close",
                    Title: "Close",
                    Display: "30 to 44 days of inactivity",
                    ValueContainerBgClass: "bg-orange-100",
                    ValueContainerFcClass: "text-orange-500"
                },
                {
                    InfoID: 4,
                    ContainerClass: "inactive",
                    Title: "Inactive",
                    Display: "45+ days of inactivity",
                    ValueContainerBgClass: "bg-pink-100",
                    ValueContainerFcClass: "text-pink-600"
                },
            ]
        };
    },
    computed: {
        episodes: function(): Episode {
            return this.store.Episode.FilterLocal((e: Episode) =>
				this.d_activeEpisodeFilters.includes(e.Info) || (this.d_showDischarged && e.Info === EpisodeInfo.Discharged),
            );
        },
    },
    methods: {
		async launchURL(externalPatientId: string, externalEpisodeId: string){
			const user = this.store.GetContextValue("UserName");
			const response = await SsoService.Launch(user, externalPatientId, externalEpisodeId);
			
			window.open(response.data, '_blank');
		},
		displayDischarge(episode: Episode, items_for_close: ItemsForClose){
			this.$emit('DisplayDischarge', episode, items_for_close);
		},
		displaySurvey(slotProps: any){
			this.$emit('DisplaySurvey', slotProps)
		},
		displayEpisodeClaim(episodeId: number){
			this.$emit('DisplayEpisodeClaim', episodeId)
		},
        episodesWithStatusCount(info: EpisodeInfo) {
            return this.store.Episode.State.Episodes.filter((e: Episode) => e.Info === info).length;
        },
        toggleCard(info: EpisodeInfo) {
			const index = this.d_activeEpisodeFilters.indexOf(info);
			index > -1 ? this.d_activeEpisodeFilters.splice(index, 1) : this.d_activeEpisodeFilters.push(info);

			this.$emit('CardToggle', info);
        },
        formatDateTime(date: string) {
            const newDate = new Date(date);

            if (date !== null && newDate.getFullYear() !== 1 && newDate.getFullYear() !== 0)
                return newDate.getMonth() + 1 + '/' + newDate.getDate() + '/' + newDate.getFullYear();
        },
        onRowExpand(event: any) {
			const selectedEpisode = this.store.Episode.Get(event.data.EpisodeId);
			this.expandedRows = [selectedEpisode];
			this.store.SetStateValue('SelectedEpisode', selectedEpisode);
        },
    },
});
