<template>
	<div :class="['vue-splash', { 'vue-splash--fixed': true }]" :style="vueSplashStyle">
		<div>
			<div class="vue-splash__anim">
				<img :src="logo" :style="imageStyle" />
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AppSplash',
	props: {
		size: {
			type: [Number, String],
			default: 180,
		},
	},
	created() {
		document.documentElement.style.setProperty('--splash-color', '#F9423A');
	},
	computed: {
		logo() {
            return require('../assets/nh_brand_symbol.png');
        },
		imageStyle() {
			let width = this.size + 'px';
			return {
				width,
			};
		},
		vueSplashStyle() {
			return {
				backgroundColor: 'transparent',
			};
		},
	},
});
</script>