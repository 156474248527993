import * as R4 from 'fhir/r4';
import { getExtensionValue } from '@/utilities/r4-ext-utils';

export default class Patient {
    private internalID: number | null = null;
    private firstName: string = '';
    private lastName: string = '';
    private middleIntial: string = '';
    private dateOfBirth: Date | null = null;
    private email: string = '';
    private gender: string = '';
    private language: string = '';
    private externalID: string = '';
    private externalSiteID: string = '';
    private alias: string = '';

    set Alias(al: R4.Identifier | string) {
        if (typeof al !== 'string') {
            if (al.value) this.alias = al.value;
        }
        else if (al) this.alias = al;
    }

    get Alias() {
        return this.alias;
    }

    set InternalID(id: number | null) {
        this.internalID = id;
    }

    get InternalID() {
        return this.internalID;
    }

    set ExternalSiteID(id: R4.Reference | string) {
        if (typeof id !== 'string') {
            if (id && id.reference) this.externalSiteID = id.reference.toLowerCase().replace('organization/', '');
        }
        else if (id) this.externalSiteID = id;
    }

    get ExternalSiteID() {
        return this.externalSiteID;
    }

    get ExternalID() {
        return this.externalID;
    }

    set ExternalID(id: R4.Identifier | string) {
        let _extId = "";

        if (typeof id !== 'string') {
            if (id && id.value) _extId = id.value;
        }
        else if (id) _extId = id;

        if(_extId.length >= 32)
            _extId = _extId.slice(0, 32);
        
        if(_extId.endsWith("."))
            _extId = _extId.substring(0, _extId.length - 1);

        this.externalID = _extId;
    }

    set Language(lang: R4.PatientCommunication | string) {
        if (typeof lang !== 'string') {
            if (lang && lang.language.coding) this.language = lang.language.coding[0].code ?? '';
        }
        else if (lang) this.language = lang.substring(0, 2);
    }

    get Language() {
        return this.language;
    }

    set Gender(gender: string) {
        if (gender.length > 0) this.gender = gender[0];
    }

    get Gender() {
        return this.gender;
    }

    set Email(email: R4.ContactPoint | string) {
        if (email) {
            let em: string = '';

            if (typeof email !== 'string') {
                if (email.value) em = email.value;
            }
            else em = email;

            if (em.includes('@') && em.includes('.')) this.email = em;
        }
    }

    get Email() {
        return this.email;
    }

    set DateOfBirth(date: string) {
        this.dateOfBirth = new Date(date);
    }

    get DateOfBirth() {
        return this.dateOfBirth?.toDateString() ?? '';
    }

    get DateOfBirthStr() {
        return this.dateOfBirth ?? `/Date(${this.dateOfBirth.getTime()}-0400)/`;
    }

    set Name(name: R4.HumanName | string) {
        if (typeof name !== 'string') {
            if (name.family)
                this.lastName = name.family;

            if (name.given) {
                if (name.given?.length > 0) this.firstName = name.given[0];
                if (name.given.length > 1) this.middleIntial = name.given[1][0];
            }
        } else {
            const nameParts: string[] = name.split(' ');

            if (nameParts.length > 1) {
                this.firstName = nameParts[0];

                if (nameParts.length > 2) {
                    this.middleIntial = nameParts[1][0];
                    this.lastName = nameParts[2];
                }
                else this.lastName = nameParts[1];
            }
        }
    }

    get Name() {
        if (this.middleIntial) return `${this.firstName} ${this.middleIntial} ${this.lastName}`;
        else return `${this.firstName} ${this.lastName}`;
    }

    set FirstName(fName: string) {
        this.firstName = fName;
    }

    get FirstName() {
        return this.firstName;
    }

    set LastName(lName: string) {
        this.lastName = lName;
    }

    get LastName() {
        return this.lastName;
    }

    set MiddleIntitial(mInitial: string) {
        if (mInitial) this.middleIntial = mInitial[0];
    }

    get MiddleInitial() {
        return this.middleIntial;
    }

    ToJSON(): string {
        return JSON.stringify([
            {
                FirstName: this.FirstName,
                LastName: this.LastName,
                Alias: this.Alias,
                DateOfBirth: this.dateOfBirth,
                Email: this.Email,
                ExternalId: this.ExternalID,
                ExternalSiteId: this.ExternalSiteID,
                Gender: this.Gender,
                Language: this.Language,
                MiddleInitial: this.MiddleInitial,
            },
        ]);
    }

    static FromResource(pat: R4.Patient, managing_organization: string): Patient {
        const patient = new Patient();
        patient.ExternalSiteID = null;

        if (pat.id) patient.ExternalID = pat.id;

        if (pat.communication) patient.Language = pat.communication[0];
        else patient.Language = 'en';

        patient.Gender = getExtensionValue(pat, 'us-core-birthsex') || 'U';

        if (pat.name) patient.Name = pat.name.filter(x => x.use == 'official')[0] as R4.HumanName;

        if (pat.birthDate && pat.birthDate !== '') patient.DateOfBirth = pat.birthDate;
        else throw new Error('Unable to find patient birthdate. Please provide a birthdate for this patient.');

        if (pat.telecom) patient.Email = pat.telecom.filter(x => x.system == 'email')[0];

        if (pat.identifier && pat.identifier.length > 0) {
            let alias = pat.identifier.filter(i => i.type?.text?.toUpperCase().includes("MRN"));

            if (!alias || alias.length == 0)
                alias = pat.identifier.filter(i => i.type?.text?.toUpperCase().includes("MR"));

            if (!alias || alias.length == 0)
                alias = pat.identifier.filter(i => i.type?.text?.toUpperCase() == "EPI");

            if (alias && alias.length > 0) patient.Alias = alias[0];
        }

        return patient;
    }
}
