
import { inject } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import Patient from '../../models/foto/patient';
import Email from '../../models/foto/email';
import SurveyDetails from '../../models/foto/surveyDetails';
import ToastService from '@/services/toastService';

export default defineComponent({
	name: 'SurveyLaunchForm',
	setup: function(): any {
		return {
			store: inject('store'),
		};
	},
	props: {
		episodeId: Number,
	},
	async created() {
		this.patient = this.store.GetStateValue('FotoPatient') as Patient;
		this.surveyDetails = await this.store.Episode.GetSurvey(this.episodeId);
		this.loading = false;
	},
	data: function(): any {
		return {
			sending: false,
			loading: true,
			patient: null,
			surveyDetails: new SurveyDetails(),
		};
	},
	methods: {
		launchSurvey() {
			window.open(this.surveyDetails.Url);
			this.store.AddLog(`Launched survey ${this.surveyDetails.Url}`);
		},
		async sendEmail() {
			try {
				this.store.AddLog(`Sending survey email for episode ${this.episodeId} to email ${this.patient.Email}`);
				this.$Progress.start();
				this.sending = true;

				const email = new Email(this.patient.Email, this.patient.Name);
				const episode = await this.store.Episode.Get(this.episodeId);
				episode.Artifacts = await this.store.Artifact.ForEpisode(episode.ExternalEpisodeId);

				if (episode.Artifacts.length === 0) {
					email.Content = `<p>Hi ${this.patient.firstName},</p><p>The comprehensive evaluation that you will have to start your therapy treatment includes a 
                        computerized functional assessment that will help your clinician better understand your condition and how it impacts your quality of life. 
                        This information will help your clinician develop treatment goals with you and is an important part of your treatment.</p><p>You have the option of completing 
                        the survey online prior to your first appointment, rather than in the clinic before your first treatment.</p><p>Please click the link to take your survey.  
                        <a href="${this.surveyDetails.Url}">${this.surveyDetails.Url}</a></p><p>The information you share is confidential, a part of your medical record, and is subject to all 
                        protected health care information regulations.</p><p>Thank you,</p><p>FOTO</p>`;
				} 
				else {
					email.Content = `<p>Hi ${this.patient.firstName},</p><p>At the beginning of your therapy treatment you completed a computerized functional assessment related to your impairment. 
                        Please complete the questionnaire again to reassess how the treatment for your impairment has helped to improve your function and pain. You will also have the opportunity 
                        to respond regarding your satisfaction with several aspects of your treatment.</p><p>Please click the link to take your survey. <a href="${this.surveyDetails.Url}">${this.surveyDetails.Url}</a></p>
                        <p>The information you share is confidential, a part of your medical record, and is subject to all protected health care information regulations.</p><p>Thank you,</p><p>FOTO</p></p>`;
				}

				const response = await this.store.Artifact.EmailSurveyInformation(email);

				if(response.successful)
					ToastService.Success("Email sent successfully!");
				else
					ToastService.Error(`Error sending email: ${response.errorMessage}`);

				this.sending = false;
				this.$Progress.finish();
			} 
			catch (er) {
				this.sending = false;
				this.$Progress.finish();
				ToastService.Error(`Error sending email: ${er.message}`)
				Promise.reject(er);
			}
		},
	},
	computed: {
		validEmail() {
			return (
				this.patient.email && this.patient.email.indexOf('@') !== -1 && this.patient.email.indexOf('.') !== -1
			);
		},
	},
});
