import FhirService from "@/services/fhirService";
import * as R4 from 'fhir/r4';
import { reactive, readonly } from "vue";

export default class FhirStore {

    private FhirService: FhirService;
    private _encounterId: string;
    private _patientId: string;
    private _practitionerId: string;
    private _fhirState: any = reactive({
        ValueSet: {
            EpisodeOfCareType: []
        }
    });
    public State: any = readonly(this._fhirState);

    constructor(encounterId: string, patientId: string, practitionerId: string){
        this._encounterId = encounterId;
        this._patientId = patientId;
        this._practitionerId = practitionerId;
    }

    async InitAsync(){
        this.FhirService = new FhirService();
        this._fhirState.Practitioner = await this.SetPractitioner();
        this._fhirState.Patient = await this.SetPatient();
        this._fhirState.Encounter = await this.SetEncounter();
        this._fhirState.EpisodeOfCare = await this.SetEpisodeOfCare();
        this._fhirState.Allergies = await this.SetList('allergies');
        this._fhirState.Problems = await this.SetList('problems');
        this._fhirState.Medications = await this.SetList('medications');
        this._fhirState.Immunizations = await this.SetList('immunizations');
        this._fhirState.ValueSet.EpisodeOfCareType = await this.GetValueSet('EpisodeOfCare.type');
    }

    public async Create(resource: R4.Resource){
        return await this.FhirService.Post(resource);
    }

    public async Search(resourceType: string, uri: string){
        return await this.FhirService.Search(`${resourceType}?${uri}`);
    }

    public async EpisodeSearch(type: string){
        return await this.FhirService.Search(`EpisodeOfCare?patient=${this._patientId}&type=${type}`);
    }

    private async GetValueSet(name: string){
        return await this.FhirService.ExpandValueSet(`context=${name}&contextDirection=outgoing`)
    }

    private async SetList(code: string){
        let queryParams = `List?patient=${this._patientId}&code=${code}`;

        if(this.State.Encounter)
            queryParams += `&encounter=${this._encounterId}`;

        const list = await this.FhirService.Search(queryParams);
        return (list as R4.Bundle).entry.map(e => e.resource);
    }

    private async SetPractitioner() {
        try {
            return await this.FhirService.Read<R4.Practitioner>(`Practitioner/${this._practitionerId}`);
        } catch (error) {
            return null;            
        }
    }

    private async SetPatient() {
        return await this.FhirService.Read<R4.Patient>(`Patient/${this._patientId}`);
    }

    private async SetEncounter() {
        try{
            return await this.FhirService.Read<R4.Patient>(`Encounter/${this._encounterId}`);
        }
        catch(e){
            // do nothing
        }
    }

    private async SetEpisodeOfCare() {
        if(this.State.Encounter){
            const encounterAssociatedEpisodes = this.State.Encounter.episodeOfCare;
            const episodeOfCare = [];
    
            if (encounterAssociatedEpisodes && encounterAssociatedEpisodes?.length > 0) {
                for(let count = 0; count < encounterAssociatedEpisodes.length; count++){
                    const eocRef = encounterAssociatedEpisodes[count]?.reference;
                    if (eocRef && eocRef !== '') episodeOfCare.push(await this.FhirService.Read<R4.EpisodeOfCare>(eocRef));
                }
            }
    
            return episodeOfCare;
        }
    }
}
