
import { PropType } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import { FunctionalAssessment } from '@/models/foto/functionalAssessment';
import { AssessmentAttribute } from '@/models/foto/assessmentAttribute';

export default defineComponent({
    name: 'AdditionalItemTable',
    props: {
        primaryAssessment: Object as PropType<FunctionalAssessment>,
    },
    data: function(): any{
        return {
            predictedChange: null,
            predicatedStageMeasure: null,
            visits: null,
            duration: null,
            satisfaction: null,
            interpretation: null
        }
    },
    created(){
        this.predictedChange = this.getAssessmentAttribute("PredictedChange");
        this.predicatedStageMeasure = this.primaryAssessment?.StagingInfo?.PredictedStage?.Measure;
        this.visits = this.getAssessmentAttribute("Visits");
        this.duration = this.getAssessmentAttribute("Duration");
        this.satisfaction = this.getAssessmentAttribute("Satisfaction");
        this.interpretation = this.generateInterpretation();
    },
    methods: {
        getAssessmentAttribute(key: string) {
            const attribute: Array<AssessmentAttribute> = this.primaryAssessment.AssessmentAttributes.filter(
                (aa: AssessmentAttribute) => aa.Attribute.toLowerCase() == key.toLowerCase(),
            );

            return attribute && attribute.length > 0 ? attribute[0].Value : '';
        },
        generateInterpretation() {
            const predictedChange: number = parseInt(this.predictedChange);
            const higherOrLowerTxt: string = predictedChange > 0 ? 'higher' : 'lower';
            const predictedChangeObj = this.primaryAssessment?.StagingInfo?.PredictedStage;
            const dischargeFsScore = predictedChangeObj?.Measure;
            const dischargeStage = predictedChangeObj?.Stage;

            let interpretation = `Given this patient’s risk-adjustment variables, and the actual Intake FS score, FOTO predicts this
                patient will experience at least an ${higherOrLowerTxt === 'higher' ? 'increase' : 'decrease'} in 
                function of ${predictedChange} points (to ${dischargeFsScore} or ${higherOrLowerTxt})`;

            if(dischargeStage != 0)
                interpretation += `, putting them in the Stage ${dischargeStage} level or ${higherOrLowerTxt} at discharge.`

            return interpretation;
        },
    },
});
